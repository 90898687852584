/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import { createContext, useCallback, useContext, useEffect, useMemo, useState, ReactNode } from 'react';
import { UserAPI, UserRepositoryAPI } from '@api/services/user-api.resources';
import { withContext } from '@app/HOCs/withContext';
import { AxiosService } from '@app/services/axios.service';
import { handleLogout } from '@app/utils/handle-logout.utils';
import { useConfig } from '@components/context/config/config.context';
import { useService } from '@components/context/service/service.context';
import { AccessDecisionManager } from '@services/access-decision-manager.service';
import { User } from '@root/src/types/user.types';
import { IAuthContext } from '@components/context/auth/auth.types';
import { setGlobalState } from '@root/src/store/global.actions';
import { GlobalActions } from '@app/store/use.global.store';
import { ANONYMOUS } from '@lib/common.constants';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('AuthContext must be used within a AuthProvider');
  }
  return context;
};

export const withAuth = withContext(AuthContext, 'auth');

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User>();
  const { isExternal } = useConfig();
  const { onRequestError } = useService();

  const isAnonymousPage = window.location?.pathname?.includes(`/${ANONYMOUS}`);

  const isUserGA = !!user && AccessDecisionManager.isGlobalAdmin(user);
  const isUserTenantAdmin = !!user && AccessDecisionManager.isTenantAdmin(user);
  const isUserTenantExpert = !!user && AccessDecisionManager.isTenantExpert(user);

  const tenantId = useMemo(() => AxiosService.getTenantId(), []); // Tenant Id

  useEffect(() => {
    /* Synchronize data of current user (based on cookies) with app context. */

    const loadUser = () => {
      UserRepositoryAPI.getCurrentUser()
        .then((user) => {
          setUser(user);

          setGlobalState({
            currentUser: user,
            isUserGA: AccessDecisionManager.isGlobalAdmin(user),
            isUserTenantAdmin: AccessDecisionManager.isTenantAdmin(user),
            isUserTenantExpert: AccessDecisionManager.isTenantExpert(user),
            isTenantMember: AccessDecisionManager.isTenantMember(user),
            isTenantGuest: AccessDecisionManager.isTenantGuest(user),
          }, GlobalActions.setUserProfile);
        })
        .catch(onRequestError);
    };

    if (tenantId && !isAnonymousPage) {
      loadUser();
    }

  }, [tenantId, isExternal, isAnonymousPage]);

  const logout = useCallback(() => {
    UserAPI.logout().then(handleLogout);
  }, []);

  const value = useMemo(
    () => ({
      user: user as User,
      tenantId,
      logout,
      isUserGA,
      isUserTenantAdmin,
      isUserTenantExpert,
    }),
    [user, tenantId, logout, isUserGA, isUserTenantAdmin, isUserTenantExpert],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
